import request from '@/utils/request.js';
/**
 *分页列表
 * @returns {Promise}
 */
export function getDuTyDetailedListPageApi(params) {
  return request({
    url: '/traffic-construction/duty/getDuTyDetailedListPage',
    method: 'get',
    params
  });
}

/**
 *获取交建下的所有人员（支持模糊查询）
 * @returns {Promise}
 */
export function getUserListApi(params) {
  return request({
    url: '/traffic-construction/getUserList',
    method: 'get',
    params
  });
}

/**
 *新增履职记录
 * @returns {Promise}
 */
export function addDutyRecordApi(data) {
  return request({
    url: '/traffic-construction/duty/addDutyRecord',
    method: 'post',
    data
  });
}

/**
 *查看履职记录详情
 * @returns {Promise}
 */
export function getDutyRecordDetailApi(params) {
  return request({
    url: '/traffic-construction/duty/getDutyRecordDetail',
    method: 'get',
    params
  });
}

/**
 *删除履职记录
 * @returns {Promise}
 */
export function deleteDutyRecordApi(data) {
  return request({
    url: '/traffic-construction/duty/deleteDutyRecord',
    method: 'post',
    data
  });
}
