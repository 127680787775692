var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"查看详情","visible":_vm.show,"width":"1065px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.handelClose}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.spinLoading),expression:"spinLoading"}],staticStyle:{"padding":"0 24px 24px 24px"}},[_c('div',{staticClass:"content-box"},[_c('span',{staticClass:"content-title"},[_vm._v("责任清单")]),_c('div',{staticClass:"content-info"},[_vm._v(_vm._s(_vm.dutyContent))])]),_c('div',{staticClass:"content-box"},[_c('span',{staticClass:"content-title"},[_vm._v("履职清单")]),_c('div',{staticClass:"content-info"},[_vm._v(_vm._s(_vm.fulfillContent))])]),_c('div',{staticClass:"tabel-title"},[_c('span',{staticClass:"content-title"},[_vm._v("履职记录")])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailtabelList,"stripe":"","border":"","max-height":"400px"}},[_c('el-table-column',{attrs:{"label":"序号","width":"55","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"description","label":"履职描述","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"履职时间","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.dutyDate.substr(0, 11)))])]}}])}),_c('el-table-column',{attrs:{"label":"履职附件","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sysFileInfos.length === 1)?_c('div',_vm._l((row.sysFileInfos),function(item){return _c('span',{key:item.fileId,staticClass:"view-details",on:{"click":function($event){return _vm.uploadFile(row.fileId)}}},[_vm._v(_vm._s(item.fileOriginName))])}),0):_c('el-popover',{attrs:{"placement":"right","width":"350","trigger":"click"}},[_c('el-table',{attrs:{"data":row.sysFileInfos}},[_c('el-table-column',{attrs:{"property":"fileOriginName","label":"履职附件","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"view-details"},[_vm._v(_vm._s(row.fileOriginName))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"view-details",on:{"click":function($event){return _vm.uploadFile(row.fileId)}}},[_vm._v("点击下载")])]}}],null,true)})],1),_c('div',{attrs:{"slot":"reference"},slot:"reference"},_vm._l((row.sysFileInfos),function(item,index){return _c('span',{key:item.fileId,staticClass:"view-details"},[_vm._v(_vm._s(item.fileOriginName)+_vm._s(index === row.sysFileInfos.length - 1 ? '' : ','))])}),0)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"createUser","label":"上传人","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","fixed":"right","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"danger-btn",on:{"click":function($event){return _vm.handleDelete(row.id)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }