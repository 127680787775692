<template>
  <div>
    <el-table :data="responsibilityListData" stripe style="width: 100%" v-loading="tabelLoading">
      <el-table-column label="序号" width="55" fixed align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="dutyContent" label="责任清单" fixed align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="fulfillContent" label="履职清单" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="address" label="操作" fixed="right" width="230" align="center">
        <template slot-scope="{ row }">
          <span class="action-btn" @click="handleDetail(row.dutyId, row.fulfillId)" style="margin-right:16px"
            >查看详情</span
          >
          <span class="action-btn" @click="handleUploadInfo(row.dutyId, row.fulfillId)">上传履职记录</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="listPamers.pageNo"
      :page-size="listPamers.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- 上传履职记录dialog -->
    <el-dialog
      title="上传履职记录"
      :visible.sync="visibleDialog"
      width="590px"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="履职描述" prop="description">
              <el-input
                style="width:364px"
                v-model="ruleForm.description"
                placeholder="请输入履职描述"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="履职时间" prop="dutyDate">
              <el-date-picker
                style="width:364px"
                v-model="ruleForm.dutyDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择履职时间"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="关联履职人">
              <el-select
                style="width:364px"
                v-model="ruleForm.userIds"
                placeholder="请选择关联履职人"
                multiple
                collapse-tags
              >
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="履职附件">
              <upload-multiple-file
                v-model="ruleForm.fileId"
                :default-list="defaultList"
                @get-file-data="getFileData"
              ></upload-multiple-file>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 查看详情dialog -->
    <detail-dialog :detail-visible.sync="detailVisible" :duty-id="dutyId" :fulfill-id="fulfillId"></detail-dialog>
  </div>
</template>

<script>
import { getDuTyDetailedListPageApi, getUserListApi, addDutyRecordApi } from 'api/general-user/responsibility-list';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import detailDialog from './detailDialog.vue';

export default {
  components: {
    UploadMultipleFile,
    detailDialog
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tabelLoading: false,
      visibleDialog: false,
      btnLoading: false,
      detailVisible: false,
      responsibilityListData: [],
      total: 0,
      listPamers: {
        pageSize: 10,
        pageNo: 1
      },
      ruleForm: {
        userIds: [],
        fileId: ''
      },
      rules: {
        description: [
          { required: true, message: '请输入履职描述', trigger: 'blur' },
          { min: 0, max: 50, message: '最大字符为50', trigger: 'blur' }
        ],
        dutyDate: [{ required: true, message: '请选择履职时间', trigger: 'change' }]
      },
      userList: [],
      defaultList: [],
      //查看详情数据
      dutyId: '', //责任清单id
      fulfillId: '' //履职清单id
    };
  },
  methods: {
    //查看详情
    handleDetail(dutyId, fulfillId) {
      this.dutyId = dutyId;
      this.fulfillId = fulfillId;
      this.detailVisible = true;
    },
    //上传履职记录
    handleUploadInfo(dutyId, fulfillId) {
      this.ruleForm.dutyId = dutyId;
      this.ruleForm.fulfillId = fulfillId;
      this.visibleDialog = true;
      this.getUserList();
    },
    getFileData(fileIdList) {
      this.ruleForm.fileId = fileIdList.join(',');
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addDutyRecord();
        }
      });
    },
    //新增履职记录Api
    addDutyRecord() {
      this.btnLoading = true;
      addDutyRecordApi(this.ruleForm)
        .then(res => {
          if (res.code === 200) {
            this.$message.success('新增成功');
            this.visibleDialog = false;
            this.btnLoading = false;
            this.getDuTyDetailedListPage();
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    dialogClose() {
      this.visibleDialog = false;
      this.defaultList = [];
      this.ruleForm={};
      this.$refs.ruleForm.resetFields();
    },
    //获取交建下的所有人员Api
    getUserList() {
      getUserListApi().then(res => {
        if (res.code === 200) {
          this.userList = res.data;
        }
      });
    },
    //分页查询
    getDuTyDetailedListPage() {
      this.tabelLoading = true;
      getDuTyDetailedListPageApi(this.listPamers).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { records, total } = res.data;
          this.total = total;
          this.responsibilityListData = records;
        }
      });
    },
    handleSizeChange(size) {
      this.listPamers.pageNo = 1;
      this.listPamers.pageSize = size;
      this.getDuTyDetailedListPage();
    },
    handleCurrentChange(current) {
      this.listPamers.pageNo = current;
      this.getDuTyDetailedListPage();
    }
  },
  created() {
    this.getDuTyDetailedListPage();
  }
};
</script>
