<template>
  <el-dialog title="查看详情" :visible.sync="show" width="1065px" :close-on-click-modal="false" @close="handelClose">
    <div style="padding:0 24px 24px 24px" v-loading="spinLoading">
      <!-- 责任清单信息 -->
      <div class="content-box">
        <span class="content-title">责任清单</span>
        <div class="content-info">{{ dutyContent }}</div>
      </div>
      <div class="content-box">
        <span class="content-title">履职清单</span>
        <div class="content-info">{{ fulfillContent }}</div>
      </div>
      <div class="tabel-title">
        <span class="content-title">履职记录</span>
      </div>
      <!-- 列表 -->
      <el-table :data="detailtabelList" stripe style="width: 100%" border max-height="400px">
        <el-table-column label="序号" width="55" fixed align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="履职描述" align="center" show-overflow-tooltip> </el-table-column>
        <el-table-column label="履职时间" align="center" width="100">
          <template slot-scope="{ row }">
            <span>{{ row.dutyDate.substr(0, 11) }}</span>
          </template>
        </el-table-column>
        <!-- 这里写得有点多 -->
        <el-table-column label="履职附件" align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <!-- 文件数量等于1的情况 -->
            <div v-if="row.sysFileInfos.length === 1">
              <span
                v-for="item in row.sysFileInfos"
                :key="item.fileId"
                class="view-details"
                @click="uploadFile(row.fileId)"
                >{{ item.fileOriginName }}</span
              >
            </div>
            <!-- 文件数量大于1的情况 -->
            <el-popover placement="right" width="350" trigger="click" v-else>
              <el-table :data="row.sysFileInfos">
                <el-table-column property="fileOriginName" label="履职附件" show-overflow-tooltip>
                  <template slot-scope="{ row }">
                    <span class="view-details">{{ row.fileOriginName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                  <template slot-scope="{ row }">
                    <span class="view-details" @click="uploadFile(row.fileId)">点击下载</span>
                  </template>
                </el-table-column>
              </el-table>
              <div slot="reference">
                <span v-for="(item, index) in row.sysFileInfos" :key="item.fileId" class="view-details"
                  >{{ item.fileOriginName }}{{ index === row.sysFileInfos.length - 1 ? '' : ',' }}</span
                >
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createUser" label="上传人" align="center" width="120"> </el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" width="120" align="center">
          <template slot-scope="{ row }">
            <span class="danger-btn" @click="handleDelete(row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import downloadFileFunc from '@/utils/download-file.js';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { getDutyRecordDetailApi, deleteDutyRecordApi } from 'api/general-user/responsibility-list';
export default {
  props: {
    detailVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    dutyId: {
      //责任清单id
      type: String,
      required: true,
      default: ''
    },
    fulfillId: {
      //履职清单id
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      spinLoading: true,
      show: this.detailVisible,
      dutyContent: '',
      fulfillContent: '',
      detailtabelList: []
    };
  },
  methods: {
    uploadFile(fileId) {
      downloadFile({ fileId }).then(res => {
        downloadFileFunc(res);
      });
    },
    //删除
    handleDelete(id) {
      this.$confirm('请确认是否删除该履职记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteDutyRecordApi(id);
      });
    },
    //删除api
    deleteDutyRecordApi(id) {
      deleteDutyRecordApi({ id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.getDutyRecordDetail();
        }
      });
    },
    //查看详情
    getDutyRecordDetail() {
      getDutyRecordDetailApi({ dutyId: this.dutyId, fulfillId: this.fulfillId }).then(res => {
        if (res.code === 200) {
          this.spinLoading = false;
          const { dutyContent, fulfillContent, dutyRecordResults } = res.data;
          this.dutyContent = dutyContent;
          this.fulfillContent = fulfillContent;
          this.detailtabelList = dutyRecordResults;
        }
      });
    },
    handelClose() {
      this.$emit('update:detailVisible', false);
    },
    cancel() {
      this.$emit('update:detailVisible', false);
    }
  },
  watch: {
    detailVisible(val) {
      this.show = val;
      this.getDutyRecordDetail();
    }
  }
};
</script>

<style scoped>
.content-box {
  display: flex;
  align-items: center;
}
.content-title {
  height: 44px;
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  display: block;
  flex-shrink: 0; /* 这个属性不是很清楚 */
}
.content-info {
  color: #616266;
  margin-left: 32px;
  min-height: 44px;
}
.tabel-title {
  margin: 40px 0 8px 0;
}
.file-class {
  cursor: pointer;
  color: #005aab;
}
</style>
